/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Radio } from "@mui/material";
import XCloseIcon from "assets/icons/HeroIcons/XCloseIcon";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { closeCreatePasswordModalAction } from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import apiLibrary from "services/api";
import { Toasts } from "view/components/Toasts";
import CustomCheckbox from "view/components/CheckBox";
import { UserProfileState } from "store/userProfile/initialState";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Button from "view/components/Button";
import { fetchUserProfileAction } from "store/userProfile/reducer.actions";
import Roles from "hooks/roles";
import FormInput from "view/pages/Auth/Components/Inputs/formInput";
import { Form, Formik, FormikHelpers } from "formik";
import { createPasswordModalValidationSchema } from "utils/validationSchemas";
import { setLoadingProgress } from "store/loadingBar";
import { getRandomProgress } from "utils/getRandomProgress";
import usePermissions from "hooks/usePermissions";
import { MaterialUiSelect } from "view/components/Select";
import { invitationApps } from "utils/customBranding";

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  backgroundColor: "#ffff",
};

interface CreatePasswordModalProps {}
interface FormValues {
  password: string;
  forceToUpdatePassword: boolean;
  appName: { value: string; label: string };
}

export const CreatePasswordModal: React.FC<CreatePasswordModalProps> = () => {
  const { createPasswordModal } = useSelector(
    (state: RootState) => state.modals
  );
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const { isMyProfile } = Roles();
  const { users } = usePermissions();

  const [passwordAutoGenerate, setPasswordAutoGenerate] = useState(true);
  const [password, setPassword] = useState("");
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  const handleClose = () => {
    dispatch(closeCreatePasswordModalAction());
    setPassword("");
    setPasswordAutoGenerate(true);
  };

  const initialValues = {
    password: "",
    forceToUpdatePassword: true,
    appName: { value: "", label: "" },
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting, setFieldError }: FormikHelpers<FormValues>
  ) => {
    const payload: any = {
      ...values,
      appName: values?.appName?.value,
    };
    if (!users.canInviteUsers) {
      return;
    }
    if (!passwordAutoGenerate && values.password === "") {
      setFieldError("password", "Please enter a valid password");
      return;
    }
    dispatch(setLoadingProgress(getRandomProgress()));
    try {
      if (userProfile.contactPreference === "email") {
        payload.email = userProfile.secondaryEmail[0].email;
      } else {
        payload.phone_number = userProfile.secondaryPhoneNumber[0].phoneNumber;
      }
      const res = await apiLibrary.userProfile.inviteUser(payload);
      if (users.canViewUsersProfile) {
        dispatch(
          fetchUserProfileAction(
            createPasswordModal.data.personId,
            isMyProfile(createPasswordModal.data.personId)
          )
        );
      }
      Toasts.success(res.message);
      handleClose();
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false);
      dispatch(setLoadingProgress(100));
    }
  };

  return (
    <Modal
      open={createPasswordModal?.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      className="border-none"
    >
      <Box sx={style} className="dark:bg-secondaryLight ">
        <Formik
          initialValues={initialValues}
          validationSchema={createPasswordModalValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            resetForm,
            setFieldValue,
            isSubmitting,
            setFieldError,
          }) => (
            <Form>
              <div className="flex flex-col justify-start items-start w-[700px] rounded-lg">
                <div
                  className="relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 rounded-lg dark:bg-secondaryLight bg-bgWhite"
                  style={{
                    boxShadow:
                      "0px 2px 8px 0 rgba(2,13,36,0.1), 0px 2px 24px 0 rgba(2,13,36,0.08)",
                  }}
                >
                  <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5">
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <p className="flex-grow w-[608px] text-xl font-semibold text-left text-textMid dark:text-textWhite">
                        Create Password
                      </p>
                      <button onClick={handleClose}>
                        <XCloseIcon width={22} height={22} fill="#2C3236" />
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-2 ">
                    <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
                      <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                        <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative  rounded-[100px]">
                          <Radio
                            sx={{
                              "&.Mui-checked": { color: "#024C6F" },
                              "&:hover": { backgroundColor: "transparent" },
                              "&:focus": {
                                backgroundColor: "none",
                                boxShadow: "none",
                              },
                              margin: 0, // Set the margin to 0
                              padding: 0,
                            }}
                            checked={passwordAutoGenerate}
                            onChange={(e: any) => {
                              resetForm();
                              setFieldValue(
                                "forceToUpdatePassword",
                                e.target.checked
                              );

                              setPasswordAutoGenerate(
                                (prevState) => !prevState
                              );
                            }}
                            disableRipple
                          />
                        </div>
                      </div>
                      <div className="flex flex-col items-start justify-start flex-grow gap-2">
                        <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 gap-2.5 pl-3 pt-1">
                          <div className="flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[556px] text-base font-semibold text-left text-textMid dark:text-textWhite">
                              Automatically Generate A Password
                            </p>
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[556px] text-sm text-left text-secondaryMidLight dark:text-caption">
                              You'll be able to view and copy the password in
                              the next step
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relative w-full">
                      <div className="flex items-start justify-start w-full">
                        <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                          <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative  rounded-[100px] mt-[4px]">
                            <Radio
                              sx={{
                                "&.Mui-checked": { color: "#024C6F" },
                                "&:hover": { backgroundColor: "transparent" },
                                "&:focus": {
                                  backgroundColor: "none",
                                  boxShadow: "none",
                                },
                                margin: 0,
                                padding: 0,
                              }}
                              checked={!passwordAutoGenerate}
                              onChange={(e: any) => {
                                setPasswordAutoGenerate(
                                  (prevState) => !prevState
                                );
                              }}
                              disableRipple
                            />
                          </div>
                        </div>
                        <div className="flex flex-col items-start justify-start flex-grow ">
                          <div className="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-2.5 pl-3 pt-1">
                            <p className="self-stretch flex-grow-0 flex-shrink-0 w-[268px] text-base font-semibold text-left text-textMid">
                              Create Password
                            </p>
                            {!passwordAutoGenerate && (
                              <div className="w-full">
                                <FormInput
                                  disabled={isSubmitting}
                                  label="New Password"
                                  type="password"
                                  name="password"
                                  id="EmailOrPhone"
                                  placeholder=""
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  error={errors.password}
                                  touched={touched.password}
                                />
                                <div className="flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5">
                                  <p className="flex-grow w-[268px] text-xs text-left text-secondaryMidLight dark:text-textWhite">
                                    Use 8 or more characters
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ml-[2px] mt-1 w-full px-2">
                      <MaterialUiSelect
                        options={invitationApps}
                        placeholder={"Select An App"}
                        labelKey="label"
                        valueKey="value"
                        handleChange={(item: any) => {
                          setFieldValue("appName", item);
                        }}
                        initialValue={values?.appName?.value}
                        error={errors?.appName?.label}
                      />
                    </div>
                  <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 rounded-lg">
                    <div className="flex items-center justify-center flex-grow-0 flex-shrink-0">
                      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0 relative p-[3px] rounded-[100px]">
                        <CustomCheckbox
                          disabled={passwordAutoGenerate}
                          onChange={(event: any) =>
                            setFieldValue(
                              "forceToUpdatePassword",
                              event.target.checked
                            )
                          }
                          checked={values.forceToUpdatePassword}
                          label={
                            <div className="flex items-center justify-start flex-grow pl-2">
                              <div className="flex justify-start items-center flex-grow relative py-0.5">
                                <p className="flex-grow w-[576px] text-[15px] text-left text-textMid dark:text-textWhite">
                                  Ask user to change their password when they
                                  sign in
                                </p>
                              </div>
                            </div>
                          }
                          labelPlacement="end"
                          sx={{
                            "&.Mui-checked": { color: "#024C6F" },
                            "&:hover": { backgroundColor: "transparent" },
                            "&:focus": {
                              backgroundColor: "none",
                              boxShadow: "none",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                    <div className="flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2">
                      <Button
                        type="button"
                        text="Cancel"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="px-5 py-2"
                        width="35"
                        height="13"
                        fontStyle="font-semibold"
                        variant="outlined"
                        onClick={handleClose}
                      />

                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        text="Create"
                        filledColor="primary"
                        outlinedColor="primary"
                        textColor="textWhite"
                        className="px-5 py-2"
                        width="35"
                        height="13"
                        fontStyle="font-semibold"
                        variant="filled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
