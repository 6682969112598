import React, { useRef, useState } from 'react';
import { FormikProps, FormikValues } from 'formik';
import PlaceholderAvatar from 'assets/images/Placeholders/cover_img_placeholder.png';
import CameraIcon from 'assets/icons/HeroIcons/CameraIcon';
import Button from 'view/components/Button';
import XCloseIcon from 'assets/icons/HeroIcons/XCloseIcon';
import Placeholder from 'assets/images/Placeholders/avatar_communityProfile_placeholder.png';
import TextInput from 'view/pages/MyProfile/Components/Inputs/TextInput';
import apiLibrary from 'services/api';
import { Toasts } from 'view/components/Toasts';
import { MAXIMUM_FILE_SIZE } from 'constants/FileSize';
import GooglePlacesAutocomplete from 'Components/AddressInput';
import SearchIcon from 'assets/icons/HeroIcons/SearchIcon';
import { MaterialUiSelect } from 'view/components/Select';
import useRoles from 'hooks/roles';
import { invitationApps } from 'utils/customBranding';

interface AddCommunityProps extends FormikProps<FormikValues> {
  handleClose: any;
  activeStep: any;
  isLastStep: any;
  isFormLoading: any;
}

export const AddCommunity: React.FC<AddCommunityProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  handleClose,
  activeStep,
  isLastStep,
  isFormLoading,
}) => {
  const [isLoading, setIsLoading] = useState({
    profileImage: false,
    coverImage: false,
  });
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [coverImageUrl, setCoverImageUrl] = useState('');
  const coverImageRef = useRef<HTMLInputElement>(null);
  const profileImageRef = useRef<HTMLInputElement>(null);
  const { isSuperAdmin } = useRoles();
  const getAppNameError = (errors: any) => {
    if (typeof errors?.appName === 'object' && errors.appName !== null) {
      return errors.appName.label || '';
    }
    return undefined;
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: 'profile' | 'cover'
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      try {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          [`${imageType}Image`]: true,
        }));

        const fileSize = file.size / 1024 / 1024; // convert bytes to mb
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error('File size must be less than 10 mb!');
        }

        const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (!validImageTypes.includes(file.type)) {
          return Toasts.error(
            'Please select a valid image file (jpeg/jpg/png)'
          );
        }

        const uploadResponse = await apiLibrary.file.fileUpload(file);

        const objectUrl = URL.createObjectURL(file);
        if (imageType === 'cover') {
          setCoverImageUrl(objectUrl);

          setFieldValue('backgroundImageId', uploadResponse.data.id);
        } else {
          setProfileImageUrl(objectUrl);
          setFieldValue('profileImage', objectUrl);

          setFieldValue('profileImageId', uploadResponse.data.id);
        }
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errMsg);
      } finally {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          profileImage: false,
          coverImage: false,
        }));
      }
    }
  };

  const handleClick = (imageType: 'profile' | 'cover') => {
    const inputRef = imageType === 'cover' ? coverImageRef : profileImageRef;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className='flex flex-col justify-start items-start w-[700px] rounded-lg'>
      <div
        className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white rounded-lg'
        style={{
          boxShadow:
            '0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)',
        }}
      >
        <div className='flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5'>
          <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0'>
            <p className='flex-grow text-xl font-semibold text-left text-textMid dark:text-textMain'>
              Add Community
            </p>
          </div>
          <button onClick={handleClose} type='button'>
            <XCloseIcon width={24} height={24} viewBox='0 0 24 24' />
          </button>
        </div>
        <div className='w-full overflow-y-auto px-1 pb-4 max-h-[70vh]'>
          <div className='relative flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-2'>
            <div className='relative flex-grow-0 flex-shrink-0 w-28 h-28'>
              <div className='relative flex-grow-0 flex-shrink-0 w-28 h-28'>
                <div className='w-28 h-28 absolute left-0 top-0 rounded-[200px]'>
                  {isLoading.profileImage && (
                    <div className='absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
                      <svg
                        className='w-7 h-7 animate-spin text-primaryExtraLight'
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                      >
                        <circle
                          className='opacity-25'
                          cx='12'
                          cy='12'
                          r='10'
                          stroke='currentColor'
                          strokeWidth='4'
                        ></circle>
                        <path
                          className='opacity-75'
                          fill='currentColor'
                          d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                        ></path>
                      </svg>
                    </div>
                  )}
                  <input
                    disabled={isLoading.profileImage}
                    type='file'
                    name='profile'
                    className='absolute top-0 left-0 hidden opacity-0 cursor-pointer'
                    multiple={false}
                    ref={profileImageRef}
                    accept='.jpg, .jpeg, .png'
                    onChange={(event) => handleImageUpload(event, 'profile')}
                  />

                  <img
                    className='absolute left-[-1px] top-[-1px] w-full h-full rounded-full'
                    src={profileImageUrl ? profileImageUrl : Placeholder}
                  />
                </div>
                <button
                  disabled={isLoading.profileImage}
                  type='button'
                  onClick={() => handleClick('profile')}
                  className='flex justify-center items-center absolute left-[78px] top-[78px] gap-2 p-0.5 w-8 h-8 rounded-3xl bg-bgWhite dark:bg-secondaryLight'
                >
                  <CameraIcon className='w-5 h-5' />
                </button>
              </div>
            </div>
            <div className='flex flex-col justify-start items-start flex-grow relative gap-1.5'>
              {isLoading.coverImage && (
                <div className='absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/3 left-1/2'>
                  <svg
                    className='w-9 h-9 animate-spin text-primaryExtraLight'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                </div>
              )}
              <input
                disabled={isLoading.coverImage}
                type='file'
                name='cover'
                className='absolute top-0 left-0 hidden opacity-0 cursor-pointer'
                multiple={false}
                ref={coverImageRef}
                accept='.jpg, .jpeg, .png'
                onChange={(event) => handleImageUpload(event, 'cover')}
              />
              <img
                src={coverImageUrl ? coverImageUrl : PlaceholderAvatar}
                className='object-cover w-full h-[120px]'
              />
              <button
                type='button'
                disabled={isLoading.coverImage}
                onClick={() => handleClick('cover')}
                className='absolute right-0 flex items-center justify-center mt-1 mr-1 border-none rounded-full outline-none h-9 w-9 bg-bgWhite dark:bg-secondaryLight '
              >
                <CameraIcon className='w-7 h-7' />
              </button>
              <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5'>
                <p className='self-stretch flex-grow-0 flex-shrink-0 w-[500px] text-sm text-left text-textMidLight dark:text-textMain '>
                  Visit{' '}
                  <a
                    className='font-medium text-primary'
                    href='https://www.iloveimg.com'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://www.iloveimg.com
                  </a>{' '}
                  to crop your image. For the best result on all devices, use a
                  file of <span className='font-medium'>10 MB</span> or less.
                </p>
              </div>
            </div>
          </div>
          <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4'>
            <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight'>
              <TextInput
                label='Community Name*'
                type='text'
                placeholder='Community name'
                name='name'
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.name}
                error={errors?.name}
                touched={touched.name}
              />
            </div>
            <div className='flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0'>
              <GooglePlacesAutocomplete
                label='Marker location*'
                error={errors?.address}
                onBlur={handleBlur}
                placeholder='Geocode'
                hanndleAddressSelect={(address: any) => {
                  setFieldValue('address', address);
                }}
                handleLatitudeAndLongitude={(longitude: any, latitude: any) => {
                  setFieldValue('location.lat', latitude);
                  setFieldValue('location.long', longitude);
                }}
                // searchIcon={true}
              />
            </div>
            <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2'>
              <div className='flex items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4'>
                <div className='flex w-[296px] flex-col justify-start items-start flex-grow'>
                  <TextInput
                    label='Marker Label*'
                    type='text'
                    placeholder='Marker Label'
                    name='address'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.address}
                    error={errors?.address}
                    touched={touched.address}
                  />
                </div>
                <div className='flex w-[296px] flex-col justify-start items-start flex-grow'>
                  <TextInput
                    fieldAs='select'
                    label='Type'
                    type='select'
                    name='type'
                    data={['Open', 'Private']}
                    handleChange={(e: any) =>
                      setFieldValue('type', e.target.value)
                    }
                    handleBlur={handleBlur}
                    value={values.type}
                    error={errors?.type}
                    touched={touched.type}
                  />{' '}
                </div>
              </div>
            </div>

            {isSuperAdmin() ? (
              <div className='w-full'>
                <MaterialUiSelect
                  options={invitationApps}
                  placeholder={'Select An App'}
                  labelKey='label'
                  valueKey='value'
                  handleChange={(item: any) => {
                    setFieldValue('appName', item);
                  }}
                  initialValue={values?.appName?.value}
                  error={getAppNameError(errors)}
                />
              </div>
            ) : null}

            <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2'>
              <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0'>
                <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1 h-36'>
                  <TextInput
                    label='Description'
                    type='text'
                    rows={3}
                    fieldAs='textarea'
                    placeholder='Add your description here...'
                    name='missionStatement'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.missionStatement}
                    error={errors?.missionStatement}
                    touched={touched.missionStatement}
                  />

                  {!errors?.missionStatement && (
                    <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 '>
                      <p className='flex-grow w-[608px] text-xs text-left text-textMidLight dark:text-textMain'>
                        6000 Max Characters
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2'>
          <Button
            type='button'
            text='Cancel'
            filledColor='primary'
            outlinedColor='primary'
            textColor='textWhite'
            className='px-5 py-2'
            width='35'
            height='13'
            fontStyle='font-semibold'
            variant='outlined'
            onClick={handleClose}
          />
          <Button
            type='button'
            onClick={handleSubmit}
            disabled={
              (activeStep === 1 && values.adminIds.length === 0) ||
              (activeStep === 2 && values.organizationIds.length === 0) ||
              isFormLoading
            }
            text={isLastStep ? 'Save' : 'Next'}
            filledColor='primary'
            outlinedColor='primary'
            textColor='textWhite'
            className='px-5 py-2'
            width='35'
            height='13'
            fontStyle='font-semibold'
            variant='filled'
          />
        </div>
      </div>
    </div>
  );
};
