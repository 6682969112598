export const brandNames: any = {
  isn: 'Indigenous Sentinels Network',
  sign: 'Seacoast Indigenous Guardians Network',
};

export const getCurrentBranding = () => {
  return process.env.REACT_APP_BRAND_NAME ?? '';
};

export const invitationApps = [
  { value: 'isn', label: 'ISN' },
  { value: 'sign', label: 'SIGN' },
];

export const getAppName = (isSuperAdmin: boolean) => {
  return isSuperAdmin
    ? { value: '', label: '' }
    : {
        value: process.env.REACT_APP_BRAND_NAME,
        label: process.env.REACT_APP_BRAND_NAME,
      };
};
