import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormikProps, FormikValues } from 'formik';
import PlaceholderAvatar from 'assets/images/Placeholders/cover_img_placeholder.png';
import CameraIcon from 'assets/icons/HeroIcons/CameraIcon';
import Button from 'view/components/Button';
import XCloseIcon from 'assets/icons/HeroIcons/XCloseIcon';
import Placeholder from 'assets/images/Placeholders/organizaitons_placeholder.png';
import TextInput from 'view/pages/MyProfile/Components/Inputs/TextInput';
import apiLibrary from 'services/api';
import { Toasts } from 'view/components/Toasts';
import { MAXIMUM_FILE_SIZE } from 'constants/FileSize';
import GooglePlacesAutocomplete from 'Components/AddressInput';
import MultiSelect from 'view/components/Multiselect';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import SearchIcon from 'assets/icons/HeroIcons/SearchIcon';
import { useDropzone } from 'react-dropzone';
import { MaterialUiSelect } from 'view/components/Select';
import { invitationApps } from 'utils/customBranding';
import useRoles from 'hooks/roles';

interface OrganizationFormProps extends FormikProps<FormikValues> {
  handleClose: any;
  activeStep: any;
  isLastStep: any;
  isFormLoading: any;
}

export const OrganizationForm: React.FC<OrganizationFormProps> = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  handleClose,
  activeStep,
  isLastStep,
  isFormLoading,
}) => {
  const [isLoading, setIsLoading] = useState({
    profileImage: false,
    coverImage: false,
  });
  const { createOrganizationModal } = useSelector(
    (state: RootState) => state.modals
  );
  // const [coverImageUrl, setCoverImageUrl] = useState("");
  const coverImageRef = useRef<HTMLInputElement>(null);
  const profileImageRef = useRef<HTMLInputElement>(null);
  const { isSuperAdmin } = useRoles();

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: 'profile' | 'cover'
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      try {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          [`${imageType}Image`]: true,
        }));

        const fileSize = file.size / 1024 / 1024;
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error('File size must be less than 10 mb!');
        }

        const validImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];

        if (!validImageTypes.includes(file.type)) {
          return Toasts.error(
            'Please select a valid image file (jpeg/jpg/png)'
          );
        }

        const uploadResponse = await apiLibrary.file.fileUpload(file);

        const objectUrl = URL.createObjectURL(file);
        if (imageType === 'cover') {
          setFieldValue('coverImage', objectUrl);
          setFieldValue('coverImageId', uploadResponse.data.id);
        } else {
          setFieldValue('profileImage', objectUrl);
          setFieldValue('organizationImageId', uploadResponse.data.id);
        }
      } catch (error: any) {
        const errMsg = error?.response?.data?.message ?? error?.message;
        Toasts.error(errMsg);
      } finally {
        setIsLoading((prevLoading) => ({
          ...prevLoading,
          profileImage: false,
          coverImage: false,
        }));
      }
    }
  };

  const getAppNameError = (errors: any) => {
    if (typeof errors?.appName === 'object' && errors.appName !== null) {
      return errors.appName.label || '';
    }
    return undefined;
  };
  const handleClick = (imageType: 'profile' | 'cover') => {
    console.log(imageType, 'imageType');
    const inputRef = imageType === 'cover' ? coverImageRef : profileImageRef;
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  // const fetchAllTags = useCallback(
  //   async (searchQuery?: string) => {
  //     try {
  //       const res = await apiLibrary.dropdowns.getTagsDropdown(searchQuery);
  //       setAllTags(
  //         res.data.tags.map((p: any) => ({ label: p?.name, value: p?.id }))
  //       );
  //     } catch (error: any) {
  //       // Handle API errors
  //       console.error("Error fetching programs:", error);
  //     }
  //   },
  //   [allTags]
  // );

  // const handleAddTagsSearch = (query: string) => {
  //   fetchAllTags(query);
  // };

  // useEffect(() => {
  //   if (createOrganizationModal) {
  //     fetchAllTags();
  //   }
  // }, [createOrganizationModal]);

  // Handle file drop
  const onDrop = useCallback(async (acceptedFiles: any) => {
    console.log('acceptedFiles[0] =>', acceptedFiles[0]);
    const fileSize = acceptedFiles[0]?.size / 1024 / 1024;
    if (fileSize > MAXIMUM_FILE_SIZE) {
      Toasts.error('File size must be less than 10 mb!');
      // setImage(null);
      acceptedFiles.splice(0, acceptedFiles.length);
      return;
    }

    try {
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        profileImage: false,
        coverImage: true,
      }));
      const uploadResponse = await apiLibrary.file.fileUpload(acceptedFiles[0]);

      const objectUrl = URL.createObjectURL(acceptedFiles[0]);

      setFieldValue('coverImage', objectUrl);
      setFieldValue('coverImageId', uploadResponse.data.id);
    } catch (error) {
    } finally {
      // Set loading state to false after file upload process completes (whether successful or not)
      setIsLoading((prevLoading) => ({
        ...prevLoading,
        profileImage: false,
        coverImage: false,
      }));
    }
  }, []);
  // Dropzone configuration
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
    },
    maxFiles: 1, // Limit to a single file
  });

  return (
    <div className='flex flex-col justify-start items-start w-[700px] rounded-lg'>
      <div
        className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 p-6 bg-white rounded-lg'
        style={{
          boxShadow:
            '0px 2px 8px 0 rgba(2,13,36,0.14), 0px 2px 24px 0 rgba(2,13,36,0.08)',
        }}
      >
        <div className='flex  justify-between items-start self-stretch flex-grow-0 flex-shrink-0 py-0.5'>
          <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0'>
            <p className='flex-grow text-xl font-semibold text-left capitalize text-textMid dark:text-textMain'>
              {createOrganizationModal.editMode
                ? 'Edit organization'
                : ' Add Organization'}
            </p>
          </div>
          <button onClick={handleClose} type='button'>
            <XCloseIcon width={24} height={24} viewBox='0 0 24 24' />
          </button>
        </div>
        <div className='w-full px-1'>
          {/* <div className="relative flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-4 p-2">
            <div className="relative flex-grow-0 flex-shrink-0 w-28 h-28">
              <div className="relative flex-grow-0 flex-shrink-0 w-28 h-28">
                <div className="w-28 h-28 absolute left-0 top-0 rounded-[200px]">
                  {isLoading.profileImage && (
                    <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50">
                      <div className="inline-flex items-center">
                        <svg
                          className="w-12 h-12 mr-2 animate-spin text-primaryExtraLight"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  )}
                  <input
                    disabled={isLoading.profileImage}
                    type="file"
                    name="profile"
                    className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
                    multiple={false}
                    ref={profileImageRef}
                    accept=".jpg, .jpeg, .png"
                    onChange={(event) => handleImageUpload(event, "profile")}
                  />

                  <img
                    className="absolute left-[-1px] top-[-1px] w-full h-full rounded-full"
                    src={
                      values?.profileImage ? values?.profileImage : Placeholder
                    }
                  />
                </div>
                <button
                  disabled={isLoading.profileImage}
                  type="button"
                  onClick={() => handleClick("profile")}
                  className="flex justify-center items-center absolute left-[78px] top-[78px] gap-2 p-0.5 w-8 h-8 rounded-3xl bg-bgWhite dark:bg-secondaryLight"
                >
                  <CameraIcon className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div> */}

          <div className='relative flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 p-2'>
            <div className='flex justify-between w-full gap-4'>
              <div className='relative flex-grow-0 flex-shrink-0 w-28 h-28'>
                <div className='relative flex-grow-0 flex-shrink-0 w-28 h-28'>
                  <div className='w-28 h-28 absolute left-0 top-0 rounded-[200px]'>
                    {isLoading.profileImage && (
                      <div className='absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'>
                        <svg
                          className='w-7 h-7 animate-spin text-primaryExtraLight'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                          ></path>
                        </svg>
                      </div>
                    )}
                    <input
                      disabled={isLoading.profileImage}
                      type='file'
                      name='profile'
                      className='absolute top-0 left-0 hidden opacity-0 cursor-pointer'
                      multiple={false}
                      ref={profileImageRef}
                      accept='.jpg, .jpeg, .png'
                      onChange={(event) => handleImageUpload(event, 'profile')}
                    />

                    <img
                      className='absolute left-[-1px] top-[-1px] w-full h-full rounded-full'
                      src={
                        values?.profileImage
                          ? values?.profileImage
                          : Placeholder
                      }
                    />
                  </div>
                  <button
                    disabled={isLoading.profileImage}
                    type='button'
                    onClick={() => handleClick('profile')}
                    className='flex justify-center items-center absolute left-[78px] top-[78px] gap-2 p-0.5 w-8 h-8 rounded-3xl bg-bgWhite dark:bg-secondaryLight'
                  >
                    <CameraIcon className='w-5 h-5' />
                  </button>
                </div>
              </div>
              <div
                {...getRootProps()}
                onClick={() => handleClick('cover')}
                className='flex flex-col justify-start items-start flex-grow relative gap-1.5'
              >
                <input {...getInputProps()} ref={coverImageRef} />
                {isLoading.coverImage && (
                  <div className='absolute z-10 transform -translate-x-1/2 -translate-y-1/2 top-1/3 left-1/2'>
                    <svg
                      className='w-9 h-9 animate-spin text-primaryExtraLight'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  </div>
                )}
                <div className='flex flex-col items-center self-stretch justify-center flex-grow p-1 border border-dashed rounded-lg cursor-pointer rounbg-white border-lineDark'>
                  {values.coverImage ? (
                    <div className='self-stretch flex-grow-0 flex-shrink-0 h-[120px] relative overflow-hidden'>
                      <div className='flex justify-start items-start absolute right-0 top-0 gap-2 p-0.5 rounded-3xl bg-white'>
                        <button
                          type='button'
                          disabled={isLoading.coverImage}
                          onClick={() => handleClick('cover')}
                          className='absolute right-0 flex items-center justify-center mt-1 mr-1 border-none rounded-full outline-none pointer-events-none h-9 w-9 bg-bgWhite dark:bg-secondaryLight '
                        >
                          <CameraIcon className='w-7 h-7' />
                        </button>
                      </div>

                      <img
                        src={values.coverImage}
                        className='object-cover w-full h-full rounded-lg'
                      />
                    </div>
                  ) : (
                    <div className='flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0'>
                      <div className='relative flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          className='relative flex-grow-0 flex-shrink-0 w-6 h-6'
                          preserveAspectRatio='xMidYMid meet'
                        >
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M11 3C8.79086 3 7 4.79086 7 7C7 7.30288 7.03348 7.59674 7.09656 7.87852C7.21716 8.41729 6.8783 8.95187 6.33958 9.07271C5.00015 9.37316 4 10.571 4 12C4 13.6569 5.34315 15 7 15C7.55228 15 8 15.4477 8 16C8 16.5523 7.55228 17 7 17C4.23858 17 2 14.7614 2 12C2 9.94357 3.24073 8.1787 5.01385 7.41034C5.00466 7.27465 5 7.13781 5 7C5 3.68629 7.68629 1 11 1C13.627 1 15.858 2.68745 16.6713 5.03713C19.669 5.37085 22 7.91321 22 11C22 13.9039 19.938 16.324 17.1989 16.88C16.6577 16.9899 16.1299 16.6402 16.02 16.0989C15.9101 15.5577 16.2598 15.0298 16.8011 14.92C18.6264 14.5494 20 12.934 20 11C20 8.79086 18.2091 7 16 7C15.9732 7 15.9465 7.00026 15.9198 7.00079C15.4368 7.01024 15.0161 6.67312 14.9201 6.19971C14.5499 4.37395 12.9343 3 11 3ZM11.2929 9.29289C11.6834 8.90237 12.3166 8.90237 12.7071 9.29289L15.7071 12.2929C16.0976 12.6834 16.0976 13.3166 15.7071 13.7071C15.3166 14.0976 14.6834 14.0976 14.2929 13.7071L13 12.4142L13 22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22L11 12.4142L9.70711 13.7071C9.31658 14.0976 8.68342 14.0976 8.29289 13.7071C7.90237 13.3166 7.90237 12.6834 8.29289 12.2929L11.2929 9.29289Z'
                            className='fill-secondary dark:fill-caption'
                          />
                        </svg>
                      </div>
                      <div className='flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg'>
                        <div className='flex justify-center items-center flex-grow relative px-1 pt-1.5 pb-2'>
                          <p className='flex-grow w-[260px] text-sm text-center text-textMid dark:text-caption'>
                            Drag and drop your file here or click in this area
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='flex flex-col justify-start items-start self-stretch flex-grow-0 flex-shrink-0 relative pt-1.5'>
              <p className='self-stretch flex-grow-0 flex-shrink-0 text-left t-fullext-sm w text-textMidLight dark:text-textMain '>
                Visit{' '}
                <a
                  className='font-medium text-primary'
                  href='https://www.iloveimg.com'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  https://www.iloveimg.com
                </a>{' '}
                to crop your image. For the best result on all devices, use a
                file of <span className='font-medium'>10 MB</span> or less.
              </p>
            </div>
          </div>
          <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 '>
            <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 dark:bg-secondaryLight'>
              <TextInput
                label='Organization Name*'
                type='text'
                placeholder='Organization Name'
                name='name'
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.name}
                error={errors?.name}
                touched={touched.name}
              />
            </div>

            { !createOrganizationModal?.editMode && isSuperAdmin()  ? (
              <div className='w-full my-4'>
                <MaterialUiSelect
                  options={invitationApps}
                  placeholder={'Select An App'}
                  labelKey='label'
                  valueKey='value'
                  handleChange={(item: any) => {
                    setFieldValue('appName', item);
                  }}
                  initialValue={values?.appName?.value}
                  error={getAppNameError(errors)}
                />
              </div>
            ) : null}
            {/* 
            <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
              <AddTags
                handleAddTagsOnChange={(option: any) => {
                  console;
                  setFieldValue("tagIds", option);
                }}
                errors={errors}
                touched={touched}
                programs={allTags}
                values={values}
                handleAddTagsSearch={handleAddTagsSearch}
              />
            </div> */}
            <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2'>
              <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0'>
                <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1 h-36'>
                  <TextInput
                    label='Description'
                    type='text'
                    rows={3}
                    fieldAs='textarea'
                    placeholder='Add your description here...'
                    name='missionStatement'
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.missionStatement}
                    error={errors?.missionStatement}
                    touched={touched.missionStatement}
                    helperText={'6000 Max Characters'}
                  />

                  {/* {!errors?.missionStatement && (
                    <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 ">
                      <p className="flex-grow w-[608px] text-xs text-left text-textMidLight dark:text-textMain">
                        6000 Max Characters
                      </p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='z-10 flex items-center self-stretch justify-end flex-grow-0 flex-shrink-0 gap-2'>
          <Button
            type='button'
            text='Cancel'
            filledColor='primary'
            outlinedColor='primary'
            textColor='textWhite'
            className='z-10 px-5 h-11'
            width='35'
            height='13'
            fontStyle='font-semibold'
            variant='outlined'
            onClick={handleClose}
          />
          <Button
            type='button'
            onClick={handleSubmit}
            disabled={
              (activeStep === 1 && values.adminIds.length === 0) ||
              (activeStep === 2 && values.organizationIds.length === 0) ||
              isSubmitting
            }
            text={createOrganizationModal.editMode ? 'Save' : 'Next'}
            filledColor='primary'
            outlinedColor='primary'
            textColor='textWhite'
            className='z-10 w-24 h-11'
            width='35'
            height='13'
            fontStyle='font-semibold'
            variant='filled'
          />
        </div>
      </div>
    </div>
  );
};

const AddTags = ({
  touched,
  errors,
  handleAddTagsOnChange,
  programs,
  values,
  handleAddTagsSearch,
  isEditMode,
}: any) => {
  const [inputValue, setInputValue] = useState('');
  const { tagIds } = values;
  return (
    <div className='flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 mt-2'>
      <div className='flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0'>
        <div className='flex flex-col items-start self-stretch justify-start flex-grow gap-1 rounded'>
          <div className='relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 pt-1'>
            <p className='flex-grow w-[596px] text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption '>
              Tags
            </p>
          </div>
          <div
            className={`flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-1.5 px-3  rounded bg-white border ${
              touched.tagIds && errors.tagIds
                ? 'border-accent_1Dark'
                : 'border-lineDark dark:border-lineLight'
            }`}
          >
            <SearchIcon />
            <div className='flex flex-col items-start justify-start flex-grow w-full dark:bg-secondaryLight '>
              <MultiSelect
                placeholder='Search Tags'
                inputValue={inputValue}
                onInputChange={(value: string) => {
                  setInputValue(value);
                  handleAddTagsSearch(value);
                }}
                options={programs}
                defaultValue={{ value: '', label: '' }}
                isMulti={true}
                closeOnSelect={false}
                value={tagIds}
                isDisable={isEditMode}
                transformY='translateY(-130%)'
                onChange={(option: any) => {
                  handleAddTagsOnChange(option);
                }}
              />
            </div>
          </div>
          <div className='flex justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative py-0.5'>
            {touched.tagIds && errors.tagIds && (
              <p className='flex-grow w-[1/2] text-xs text-left text-accent_1Dark'>
                {errors?.tagIds?.value}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
