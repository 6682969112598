import React, { useRef, useState } from "react";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import PopOverMenu from "view/components/PopOverMenu";
import { useSelector } from "react-redux";
import { RootState } from "store";
import useActions from "hooks/useActions";
import {
  openActiveSeasonsHistoryModalAction,
  openEditProgramModalAction,
} from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { IProgram } from "store/programProfile/initialState";
import CalendarIcon from "assets/icons/HeroIcons/CalendarIcon";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import Head from "view/components/Head";
import DotCentralIcon from "assets/icons/HeroIcons/DotCentralIcon";
import Status from "view/components/Statuses";
import CustomToolTip from "view/components/ToolTip";
import { IconButton } from "@mui/material";
import { InformationCircleIcon } from "assets/icons/HeroIcons";

export const ProfileActions = () => {
  const programProfile = useSelector<RootState, IProgram>(
    (state) => state?.programProfile
  );
  const [showTooltip, setShowTooltip] = useState(false);
  const dispatch = useDispatch();
  const { programsActions } = useActions();
  const { programs } = usePermissions();

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const editProgram = () => {
    const initialValues = {
      status: programProfile.status,
      programId: programProfile.id,
      coverImageId: programProfile.coverImageId,
      coverImage: programProfile.coverImage,
      description: programProfile.description ?? "",
      name: programProfile.name,
      leadingOrganizationId: programProfile?.organizations?.find(
        (item: any) => item.isLeading
      ),
      profileImageId: programProfile.profileImageId,
      profileImage: programProfile.profileImage,
      code: programProfile.code,
      startDate: programProfile.startDate,
      endDate: programProfile.endDate ? programProfile.endDate : "",
      requireQa: programProfile.requireQa,
      isPublic: programProfile.isPublic,
      startTime: programProfile.startTime,
      endTime: programProfile.endTime,
      openAccess: programProfile?.openAccess,
    };
    dispatch(openEditProgramModalAction(initialValues));
  };

  const getProgramsActions = () => {
    const modalData = {
      status: programProfile.status,
      id: programProfile.id,
      name: programProfile.name,
      programAdmins: programProfile?.programAdmins
        ? programProfile?.programAdmins?.map((admin: any) => {
            const person = {
              id: admin.id,
              firstName: admin.firstName,
              lastName: admin.lastName,
              profileImage: admin.profileImage.file,
              observations: admin.observationsCount,
            };
            return person;
          })
        : [],
      leadingOrganizationId: programProfile.organizations.find(
        (item: any) => item.isLeading
      )?.id,
      profileImage: programProfile.profileImage,
      observations: programProfile?.observationsCount,
      path: "profilePage",
      contactInfo: programProfile?.contactInfo,
      openAccess: programProfile?.openAccess,
      canEditProgramDetail: programProfile.canEditProgramDetail,
      canAddAdmin: programProfile?.canAddAdmin,
      appName: { value: process.env.REACT_APP_BRAND_NAME, label: process.env.REACT_APP_BRAND_NAME }
    };

    return programsActions(modalData);
  };

  const handleBtnClick = () => {
    if (programs.canViewStatusHistory) {
      dispatch(openActiveSeasonsHistoryModalAction(programProfile?.id));
    }
  };

  return (
    <div className="flex flex-col justify-start items-start flex-grow gap-1.5 pt-3">
      <Head title={`Programs ${programProfile?.name}`} />
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        <div className="flex flex-col justify-start items-start flex-grow py-0.5">
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
            <p className="break-all w-full text-[28px] font-bold text-left text-textMid dark:text-textMain">
              {programProfile?.name}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-4">
          {programProfile.status !== "completed" && (
            <>
              {programs.canEditProgram &&
                programProfile.canEditProgramDetail && (
                  <button
                    onClick={() => editProgram()}
                    className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg"
                  >
                    <PencilAltIcon />
                  </button>
                )}

              {getProgramsActions().some(
                (action: any) => action.isVisible === true
              ) ? (
                <PopOverMenu icon={<DotsVerticalIcon />}>
                  <ActionDropDownMenu actions={getProgramsActions()} />
                </PopOverMenu>
              ) : (
                <DotsVerticalIcon />
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col ">
        <p className="flex items-center text-sm font-normal text-left text-secondaryMid dark:text-caption ">
          Timeframe: <CalendarIcon height={16} fill="#2C3236" className="" />
          <span className="font-medium break-all">
            {programProfile?.startDate &&
              moment(
                programProfile?.startDate,
                DATE_FORMATS.DEFAULT
              ).isValid() &&
              moment(programProfile?.startDate, DATE_FORMATS.DEFAULT).format(
                "DD MMM YYYY"
              )}
            {programProfile.endDate &&
              moment(programProfile?.endDate, DATE_FORMATS.DEFAULT).isValid() &&
              ` - ${moment(
                programProfile?.endDate,
                DATE_FORMATS.DEFAULT
              ).format("DD MMM YYYY")}`}
          </span>
        </p>
        <div className="flex items-center mt-2">
          <p className="flex items-center text-sm text-left text-secondaryMid dark:text-caption ">
            Code:
            <span className="ml-1 font-medium break-all ">
              {programProfile?.code}
            </span>
          </p>
          <DotCentralIcon />
          <div
            onClick={handleBtnClick}
            className={`${programs.canViewStatusHistory ? "cursor-pointer" : "cursor-not-allowed"}`}
          >
            <Status status={programProfile?.status} />
          </div>

          {programProfile?.status === "draft" && (
            <div className="ml-1">
              <CustomToolTip
                title={programProfile?.message}
                showTooltip={showTooltip}
                toggleTooltip={toggleTooltip}
              >
                <IconButton onClick={toggleTooltip}>
                  <InformationCircleIcon className="w-5 h-5 fill-[#475569]" />
                </IconButton>
              </CustomToolTip>
            </div>
          )}

          {programProfile?.pausedSince && (
            <p className="ml-2 text-sm font-medium text-secondaryMid dark:text-caption">
              Paused Since: {programProfile?.pausedSince}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
