import React, { useRef } from "react";
import ActionDropDownMenu from "Components/ActionDropDownMenu";
import DotsVerticalIcon from "assets/icons/HeroIcons/DotsVerticalIcon";
import PencilAltIcon from "assets/icons/HeroIcons/PencilAltIcon";
import PopOverMenu from "view/components/PopOverMenu";
import { useSelector } from "react-redux";
import { RootState } from "store";
import useActions from "hooks/useActions";
import {
  openAddEditContactInfoModalAction,
  openArchiveOrPublishOrganizationModalAction,
  openCreateOrganizationModalAction,
  openEditProgramModalAction,
  openRemoveFromProgramAction,
} from "store/modals/reducer.actions";
import { useDispatch } from "react-redux";
import { IOrganization } from "store/organizationProfile/initialState";
import CalendarIcon from "assets/icons/HeroIcons/CalendarIcon";
import moment from "moment";
import { DATE_FORMATS } from "constants/dateFormats";

import { useParams } from "react-router-dom";
import organizations from "services/api/organizations";
import usePermissions from "hooks/usePermissions";
import Head from "view/components/Head";

export const ProfileActions = () => {
  const organizationProfile = useSelector<RootState, IOrganization>(
    (state) => state?.organizationProfile
  );

  const { organizations } = usePermissions();
  const dispatch = useDispatch();

  const { organizationId, communityId, programId } = useParams();

  const editOrganization = () => {
    const {
      address,
      missionStatement,
      name,
      organizationImage,
      organizationAdmins,
      organizationCommunities,
      organizationTags,
      id,
      coverImage,
    } = organizationProfile;

    const payload = {
      communityIds: organizationCommunities,
      missionStatement: missionStatement,
      name: name,
      organizationImageId: organizationImage.id,
      coverImageId: coverImage?.id ?? null,
      coverImage: coverImage?.original,
      tagIds:
        organizationTags?.length > 0
          ? organizationTags?.map((item: any) => {
              return { value: item.id, label: item.name };
            })
          : [],
      adminIds: organizationAdmins?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          profileImage: item?.profileImage,
        };
      }),
      profileImage: organizationImage.file.original,
      id,
      openAtStep: 0,
    };
    dispatch(openCreateOrganizationModalAction(payload, true));
  };
  const organizationActions = () => {
    const {
      address,
      missionStatement,
      name,
      organizationImage,
      organizationAdmins,
      organizationCommunities,
      organizationTags,
      id,
      status,
      openAccess,
    } = organizationProfile;

    // Create the payload
    const payload = {
      communityIds: organizationCommunities,
      missionStatement: missionStatement,
      name: name,
      organizationImageId: organizationImage.id,
      tagIds:
        organizationTags?.length > 0
          ? organizationTags?.map((item: any) => {
              return { value: item.id, label: item.name };
            })
          : [],
      adminIds: organizationAdmins?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          profileImage: item.profileImage,
        };
      }),
      profileImage: organizationImage.file.original,
      id,
      openAtStep: 2,
      appName: { value: process.env.REACT_APP_BRAND_NAME, label: process.env.REACT_APP_BRAND_NAME }
    };
    return [
      {
        label: "Edit Contact Information",
        onClick: () => {
          dispatch(
            openAddEditContactInfoModalAction(organizationProfile?.contactInfo)
          );
        },
        divider: true,
        isVisible: status === "active",
      },
      {
        label: "Archive",
        onClick: () =>
          dispatch(
            openArchiveOrPublishOrganizationModalAction({
              id: id,
              status: status,
              profilePage: true,
            })
          ),
        divider: false,
        isVisible:
          status === "active" &&
          !programId &&
          !openAccess &&
          organizations.canArchiveOrganization,
      },

      {
        label: "Assign an Admin",
        onClick: () =>
          dispatch(openCreateOrganizationModalAction(payload, true)),
        divider: false,
        isVisible:
          status === "active" &&
          !programId &&
          !openAccess &&
          organizations.canViewCommunitiesMembers,
      },

      {
        label: "Restore",
        onClick: () =>
          dispatch(
            openArchiveOrPublishOrganizationModalAction({
              id: id,
              status: status,
              profilePage: true,
            })
          ),
        divider: false,
        isVisible:
          status === "archived" &&
          !programId &&
          !openAccess &&
          organizations.canArchiveOrganization,
      },

      {
        label: "Remove from program",
        onClick: () => {
          const dataa = {
            id: id,
            name: name,
            programId,
            entity: "organization",
          };
          dispatch(openRemoveFromProgramAction(dataa));
        },
        divider: false,
        isVisible: programId ? true : false,
      },
    ];
  };

  return (
    <div className="flex flex-col justify-start items-start flex-grow gap-1.5 pt-3">
      <Head title={`Organizations ${organizationProfile?.name}`} />
      <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2">
        <div className="flex flex-col justify-start items-center flex-grow py-0.5">
          <div className="relative flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
            <p className="flex-grow text-[28px] font-bold text-left break-all text-secondaryMid dark:text-textMain">
              {organizationProfile?.name}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-start flex-grow-0 flex-shrink-0 gap-4">
          <>
            {organizations.canEditOrganization && (
              <button
                onClick={() => editOrganization()}
                className="relative flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg"
              >
                <PencilAltIcon />
              </button>
            )}

            {organizationActions().some(
              (action: any) => action.isVisible === true
            ) ? (
              <PopOverMenu icon={<DotsVerticalIcon />}>
                <ActionDropDownMenu actions={organizationActions()} />
              </PopOverMenu>
            ) : (
              <DotsVerticalIcon />
            )}
          </>
        </div>
      </div>
      {/* <div className="flex flex-col ">
        <p className="flex items-center text-sm font-normal text-left text-secondaryMid dark:text-caption ">
          Timeframe: <CalendarIcon height={16} fill="#2C3236" className="" />
          <span className="font-medium break-all">
            {organizationProfile.startDate &&
              moment(organizationProfile.startDate).format("DD MMM YYYY")}
            {organizationProfile.endDate &&
              ` - ${moment(organizationProfile.endDate).format("DD MMM YYYY")}`}
          </span>
        </p>
        <p className="flex text-sm text-left text-secondaryMid dark:text-caption ">
          Code:
          <span className="ml-1 font-medium break-all ">
            {organizationProfile?.code}
          </span>
        </p>
      </div> */}
    </div>
  );
};
